import React from "react";

const B2GBreadcrumb = () => {
    return (
        <>
            <li className="breadcrumb-item">
                <span className="breadcrumb-not-active B2g_dashboard">B2G Dashboard</span>
            </li>
        </>
    )
}

export default B2GBreadcrumb;