import { createSlice } from "@reduxjs/toolkit";

const initialState = { status: '' }

const complainSearchParamsSlice = createSlice({
    name: 'complainB2g',
    initialState,
    reducers: {
        searchParamsUpdate(state, action) {
            state.status = action.payload.status;
        },
    }
})

export default complainSearchParamsSlice.reducer
export const { searchParamsUpdate } = complainSearchParamsSlice.actions
