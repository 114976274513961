import { apiSlice } from "../../../../features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query: () => `/main/dashboard`,
            transformResponse: (responseData) => {
                if (responseData?.status === false) {
                    throw new Error(`API Error: ${responseData?.statusCode || 'Unknown error'}`);
                }
                const { result, lastFifteenDaysPickups } = responseData?.data || {};
                return { result, lastFifteenDaysPickups };
            },
            providesTags: (result, error) => {
                if (error) {
                    return [];
                }
                if (result?.result) {
                    return [
                        { type: 'Dashboard', id: 'List' },
                        ...result.result.map((item, index) => ({ type: 'Dashboard', id: index })),
                    ];
                }
                return [];
            },
        }),
        getCurrentBalance: builder.query({
            query: () => `/main/currentBalance`,
            transformResponse: (responseData) => {
                if (responseData?.status === false) {
                    throw new Error(`API Error: ${responseData?.statusCode || 'Unknown error'}`);
                }
                const { balance, availableBalance } = responseData?.data || {};
                return { balance, availableBalance };
            },
            providesTags: (result, error) => {
                if (error) {
                    return [];
                }
                if (result?.result) {
                    return [
                        { type: 'Dashboard', id: 'List' },
                        ...result.result.map((item, index) => ({ type: 'Dashboard', id: index })),
                    ];
                }
                return [];
            },
        }),
    }),
});

export const {
    useGetDashboardQuery,
    useGetCurrentBalanceQuery
} = extendedApiSlice;
