import React from 'react'

const Pagination = ({ page, totalPages, setPage }) => {

    const pageMin = () => setPage((prev) => Math.max(prev - 1, 1));
    const pageAdd = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const firstPage = () => setPage(1);
    const lastPage = () => setPage(totalPages);

    return (
        <div className="pagination_box fixed-bottom mt-1 mb-3">
            <div className=" d-flex justify-content-center">
                <div className="pagination">
                    <button className="page-link" onClick={firstPage}>
                        1
                    </button>
                    <button className="page-link d-flex" disabled={page <= 1} onClick={pageMin}>
                        &laquo; Previous
                    </button>
                    <div className="page-link">{page}</div>
                    <button className="page-link" disabled={page >= totalPages} onClick={pageAdd}>
                        Next &raquo;
                    </button>
                    <button className="page-link" onClick={lastPage}>
                        {totalPages}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pagination