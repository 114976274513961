import { createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../api/apiSlice"


const complainAdaptor = createEntityAdapter({
    selectId: (item) => (item.c_id)
})

const initialComplain = complainAdaptor.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getComplainList: builder.query({
            query: (searchParams) => {
                const queryParams = []
                if (searchParams) {
                    queryParams.push(`status=${searchParams.status}`)
                }
                let url = `/b2g/eco-admin/complain`
                if (queryParams.length > 0) {
                    url = `/b2g/eco-admin/complain` + '?' + queryParams.join('&')
                }

                return url
            },
            transformErrorResponse: (err) => {
                console.error(err)
                return err
            },
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    return complainAdaptor.setAll(initialComplain, response.data)
                } else {
                    throw new Error(response.data)
                }
            },
            providesTags: (result, error, args) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [{ type: 'complainB2g', id: 'List' }, ...result?.ids.map(id => ({ type: 'complainB2g', id }))]
                }
            }
        }),
        changeComplainStatus: builder.mutation({
            query: (body) => ({
                url: `/b2g/eco-admin/complain/${body.c_id}`,
                method: 'PATCH',
                body: { c_status: body.c_status }
            }),
            transformErrorResponse: (error) => {
                console.error(error)
                return error
            },
            transformResponse: (response) => {
                if (response.statusCode !== 200) {
                    throw new Error(response)
                }
            },
            invalidatesTags: [{ type: 'complainB2g', id: 'List' }]
        }),
        getComplainStatus: builder.query({
            query: () => ('/b2g/eco-admin/complain/status'),
            transformErrorResponse: (error) => {
                console.error(error)
                return error
            },
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    return response.data
                } else {
                    throw new Error(response)
                }

            },
            providesTags: ['complainStatus', 'List']
        })
    })
})

// export const { selectAll: selectAllComplain } = complainAdaptor.getSelectors((state) => state.complaints);

export const { useGetComplainListQuery, useChangeComplainStatusMutation, useGetComplainStatusQuery } = extendedApiSlice