import React, { useState, useRef } from "react";
import { useAddNewDailyWasteMutation } from "./dailyWasteSlice";

const AddDailyWaste = ({ subCategory }) => {

    const [addNewDailyWaste, { isLoading }] = useAddNewDailyWasteMutation()
    const [addDailyWaste, setAddDailyWaste] = useState({});
    const [collectionDate, setCollectionDate] = useState(null)
    const refClose = useRef('');

    const handleClick = async (e) => {

        e.preventDefault();

        try {

            const wasteData = Object.entries(addDailyWaste)
            const wasteDataNumber = wasteData.map((item) => (item.map(subArr => (Number(subArr)))))

            const data = {
                waste:
                    JSON.stringify(wasteDataNumber)
                ,
                collection_date: collectionDate
            }

            await addNewDailyWaste(data).unwrap()
            setCollectionDate(null);
            setAddDailyWaste({});
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Waste</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Waste</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Collection Date
                                </label>
                                <input value={collectionDate || ''} onChange={(e) => { setCollectionDate(e.target.value) }} className="form-control" type='date' name='collection_date' />

                            </div>
                            <div className="col-md-12 text-left d-flex justify-content-between">
                                <div className="col-md-4 ">
                                    <label htmlFor="name" className="form-label font-weight-bold">
                                        Category
                                    </label>

                                </div>
                                <div className="col-md-4 ">
                                    <label htmlFor="name" className="form-label font-weight-bold">
                                        Collection Weight
                                    </label>

                                </div>

                            </div>
                            {
                                subCategory?.ids?.map((id) => {
                                    return (
                                        <>
                                            {/* {console.log(subCategory?.entities[id].wsc_active)} */}
                                            {(subCategory?.entities[id].wsc_active === 1) && <div className="col-md-12 text-left d-flex justify-content-between">
                                                <div className="col-md-6 ">
                                                    <input onBeforeInput={(e) => {
                                                    }} name={id} className="form-control" type="text" value={`${subCategory?.entities[id].wsc_name} (in ${subCategory?.entities[id].wsc_measure_unit})`} disabled ></input>
                                                </div>
                                                <div className="col-md-5 ">
                                                    <input value={addDailyWaste[id] || ""} onChange={(e) => { if (e.target.value === 0) { setAddDailyWaste({ ...addDailyWaste, [id]: null }) } else { setAddDailyWaste({ ...addDailyWaste, [id]: e.target.value }) } }} name={`${subCategory?.entities[id].wsc_name}Weight`} type="number" className="form-control"></input>
                                                </div>
                                            </div>}
                                        </>
                                    )
                                })
                            }

                            <div className="col-md-12">
                                <button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                // disabled={!(true)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDailyWaste;


