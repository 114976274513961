import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";



export const dailyWasteAdaptor = createEntityAdapter({
    selectId: (entity) => entity.w_id,
})

const initialDailyWaste = dailyWasteAdaptor.getInitialState()
export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDailyWaste: builder.query({
            query: (page) => `/b2g/eco-admin/waste?page=${page}`,
            transformResponse: responseData => {
                const { totalProperties, totalPage, data, page } = responseData?.data
                return { totalProperties, totalPage, entities: data, page };
            },

            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.entities) {
                    return [
                        { type: 'DailyWaste', id: 'List' },
                        ...result.entities.map((dailyWaste) => ({ type: 'DailyWaste', id: dailyWaste.id })),
                    ];
                }
            }
        }),
        addNewDailyWaste: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/waste',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'DailyWaste', id: 'List' }
            ]
        }),
        editDailyWaste: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/waste`,
                method: 'PATCH',
                body: payLoad.body
            }),
            invalidatesTags: [
                { type: 'DailyWaste', id: 'List' }
            ]
        }),
        deleteDailyWaste: builder.mutation({
            query: ({ id }) => ({
                url: `/b2g/eco-admin/waste/${id}?is_deleted=1`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'DailyWaste', id: 'List' }
            ]
        }),

    })
})

export const {
    useGetDailyWasteQuery,
    useAddNewDailyWasteMutation,
    useEditDailyWasteMutation,
    useDeleteDailyWasteMutation

} = extendedApiSlice

