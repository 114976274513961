import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../firebaseInit";
import { login, panelChange } from '../features/auth/authSlice'

const AdminSignin = ({ urlMain }) => {

    const [credentials, setCredentials] = useState({ emailId: "", password: "" });
    // eslint-disable-next-line
    const [isTokenFound, setTokenFound] = useState(false);

    const { loginError, loginStatus } = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const fcmNotification = async () => {
        try {
            const data = await getToken();
            setTokenFound(data);
        } catch (error) {
            console.error("Error fetching FCM token:", error);
        }
    };

    // eslint-disable-next-line 
    const fcm = secureLocalStorage.getItem("fcm-token");

    const changeCredentials = (e) => setCredentials({ ...credentials, [e.target.name]: e.target.value });

    const handleType = () => {
        dispatch(panelChange({ 'panelType': 'b2g' }))
    }

    const handleLogin = (e) => {
        e.preventDefault()
        dispatch(login({
            'emailId': credentials.emailId,
            'password': credentials.password,
            'token': "12345qwerty6789uiop10"
        }))
    }
    useEffect(() => {
        fcmNotification();
    }, []);

    return (
        <>
            <div className="bg">
                <div className="container vh-100 d-flex justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="text-center">
                                <img src="assets/images/ecowrap-logo.png" alt="ecowrap-logo" style={{ width: "200px" }} />
                            </div>
                            <div className="card card-transparent pt-5 ps-5 pe-5 pb-4">
                                <form >
                                    <div className="h5 fw-bolder mb-3">Admin Sign In</div>
                                    <label className="form-label mb-0" htmlFor="emailId">
                                        <small className="fw-bold">Email Id</small>
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa-solid fa-envelope"></i></span>
                                        <input
                                            type="email"
                                            id="emailId"
                                            name="emailId"
                                            className="form-control"
                                            value={credentials.emailId}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <label className="form-label mb-0" htmlFor="password">
                                        <small className="fw-bold">Password</small>
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-lock"></i></span>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-control"
                                            value={credentials.password}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    {loginError && <div style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{loginStatus}</div>}
                                    <button className="btn btn-1B9339 btn-md btn-block"
                                        disabled={loginStatus === 'pending' ? true : false}
                                        onClick={handleLogin}
                                    >
                                        {loginStatus === 'pending' ? <i className="fa fa-spinner fa-spin"></i> : "Sign In"}
                                    </button>
                                </form>
                                <div className="a-divider a-divider-break mt-3">
                                    <h5 aria-level="5">or B2G</h5>
                                </div>
                                <div className="card card-transparent" onClick={handleType}>
                                    <button className="btn btn-sm">B2G Sign In</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminSignin;