import { useGetDriversQuery } from "./driverSlice";

const DriverExcerpt = ({ driverId, index }) => {
    const { driver } = useGetDriversQuery('getDrivers', {
        selectFromResult: ({ data }) => ({
            driver: data?.entities[driverId]
        }),
    })

    return (
        <>
            <tr key={index} className="text-left fw-normal">
                <td>{index + 1}</td>
                <td>{driver?.p_name}</td>
                <td>{driver?.p_number}</td>
                <td>{driver?.p_email !== (null || '' || undefined) ? 'N/A' : (driver?.p_email)}</td>
                <td><p style={{ height: "15px", width: "15px" }} className={`rounded-circle ${driver?.is_leave === 0 ? 'bg-success' : 'bg-danger'}  `}> </p> </td>

            </tr>
        </>
    )
}

export default DriverExcerpt