import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginB2g, panelChange } from "../features/auth/authSlice";
const B2GSignin = () => {

    const [credentials, setCredentials] = useState({ email_id: "", password: "" });
    const dispatch = useDispatch()

    const { loginError, loginStatus } = useSelector(state => state.auth);

    const handleLogin = (e) => {
        e.preventDefault();
        localStorage.removeItem("tokenAdminPanel");
        dispatch(loginB2g({ number: credentials.number, password: credentials.password }))
    };
    // const handleLogin = async (e) => {
    //     e.preventDefault();
    //     dispatch(loginB2g({ number: credentials.number, password: credentials.password }))
    // };

    const changeCredentials = (e) => setCredentials({ ...credentials, [e.target.name]: e.target.value });

    const handleType = () => {
        dispatch(panelChange({ 'panelType': 'b2b' }))
    }
    return (
        <>
            <div className="bg">
                <div className="container vh-100 d-flex justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="text-center">
                                <img src="assets/images/ecowrap-logo.png" alt="ecowrap-logo" style={{ width: "200px" }} />
                            </div>
                            <div className="card card-transparent pt-5 ps-5 pe-5 pb-4">
                                <form onSubmit={handleLogin}>
                                    <div className="h5 fw-bolder mb-3">B2G Sign In</div>
                                    <label className="form-label mb-0" htmlFor="number">
                                        <small className="fw-bold">Contact Number</small>
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa-solid fa-mobile-alt"></i></span>
                                        <input
                                            type="text"
                                            id="number"
                                            name="number"
                                            className="form-control"
                                            value={credentials.number}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <label className="form-label mb-0" htmlFor="password">
                                        <small className="fw-bold">Password</small>
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-lock"></i></span>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-control"
                                            value={credentials.password}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div style={{ height: '16px' }}>
                                        {loginStatus && <p style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{loginError}</p>}
                                    </div>
                                    <button type="submit"
                                        className="btn btn-1B9339 btn-md btn-block"
                                        disabled={loginStatus === 'pending' ? true : false}
                                    >
                                        {loginStatus === 'pending' ? <i className="fa fa-spinner fa-spin"></i> : "Sign In"}
                                    </button>
                                </form>
                                <div className="a-divider a-divider-break mt-3">
                                    <h5 aria-level="5">or Admin</h5>
                                </div>
                                <div className="card card-transparent" onClick={handleType}>
                                    <button className="btn btn-sm">Admin Sign in</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default B2GSignin;