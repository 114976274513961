import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const iecPersonAdaptor = createEntityAdapter({
    selectId: (entity) => entity.inc_id,
})

const initialIECPerson = iecPersonAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIECPerson: builder.query({
            query: () => '/b2g/eco-admin/inc',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return iecPersonAdaptor.setAll(initialIECPerson, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error || !result?.ids) {
                    return [{ type: 'B2gIECPerson', id: 'List' }, []];
                } else {
                    return result.ids.map(id => ({ type: 'B2gIECPerson', id }));
                }
            }
        }),
        addNewIECPerson: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/inc',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'B2gIECPerson', id: 'List' }
            ]
        })
    })
})

export const {
    useGetIECPersonQuery,
    useAddNewIECPersonMutation
} = extendedApiSlice
