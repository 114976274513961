import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import DashboardTopCard from "./DashboardTopCard";
import DashboardMiddlePieChart from "./DashboardMiddlePieChart";
import DashboardMiddleLineChart from "./DashboardMiddleLineChart";
import DashboardBottomCard from "./DashboardBottomCard";
import SpinnerForPage from "../../../../components/spinner/SpinnerForPage";
import { useGetCurrentBalanceQuery, useGetDashboardQuery } from "./DashboardSlice";

const Dashboard = ({ urlMain, token }) => {

    const parseRole = secureLocalStorage.getItem("roles");
    const role = JSON.parse(parseRole);

    const { data: dashboard, isLoading } = useGetDashboardQuery('getDashboard');
    const { data: currentBalance } = useGetCurrentBalanceQuery('getCurrentBalance');
    const [date, setDate] = useState({
        startDate: "",
        endDate: ""
    })
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false)

    const handleChange = (e) => {
        setDate({ ...date, [e.target.name]: e.target.value })
    }

    // const handleDownload = () => {
    //     setDownloadButtonDisabled(true)
    //     if (date.startDate === "" || date.endDate === "") {
    //         setDownloadButtonDisabled(false)
    //         return
    //     }
    //     console.log(date.startDate, date.endDate, urlMain);
    //     fetch(`${urlMain}/property-pickups-data?start_date=${date.startDate}&end_date=${date.endDate}`, {
    //         method: 'GET',
    //         headers: { 'Authorization': `Bearer ${token}` }
    //     })
    //         .then(response => {
    //             console.log(response);
    //             response.blob()
    //         })
    //         .then(blob => {
    //             console.log(blob);
    //             const url = window.URL.createObjectURL(new Blob([blob]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', 'filename.csv');
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             setDownloadButtonDisabled(false)
    //         })
    //         .catch(error => {
    //             setDownloadButtonDisabled(false)
    //             console.error('Error fetching CSV file:', error)
    //         });
    // }

    useEffect(() => {
        document.title = "Dashboard - Admin Panel | CRM";
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                isLoading ? <SpinnerForPage /> :

                    <div className="container-fluid mt-5">
                        {/* <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Welcome Dashboard!</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <span className="breadcrumb-active">Dashboard</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <DashboardTopCard dashboardData={dashboard?.result} />
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-5 mt-5">
                                <div className="card card-dark widget-card-1 mb-5">
                                    <div className="card-block-small status-info">
                                        <i className="fa-solid fa-calendar-days bg-ff9b44 card1-icon"></i>
                                        <h6>Total Holidays (2024)</h6>
                                        <h4>{dashboard?.result.length === 0 ? 0 : dashboard?.result[13][0]?.totalHolidays}</h4>
                                    </div>
                                </div>
                                <div className="card card-dark widget-card-1 mb-5">
                                    <div className="card-block-small status-info">
                                        <h6>Total Balance (Current)</h6>
                                        <i className="fa-solid fa-indian-rupee-sign bg-ff9b44 card1-icon"></i>
                                        <h4>₹ {currentBalance?.balance}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 d-flex justify-content-center">
                                <DashboardMiddlePieChart dashboardData={dashboard?.result} />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-7">
                                <div className="card card-dark">
                                    <div className="card-body">
                                        <DashboardMiddleLineChart dashboardGraph={dashboard?.lastFifteenDaysPickups} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card card-dark widget-card-1 mb-5">
                                    <div className="card-block-small status-info">
                                        <i className="fas fa-rupee bg-ff9b44 card1-icon"></i>
                                        <h6>Total Amount</h6>
                                        <h4>{dashboard?.result.length === 0 ? 0 : "₹ " + (((dashboard?.result[5][0]?.TotalAmount + 320000) / 1000).toFixed(2)) + " K"}</h4>
                                    </div>
                                </div>
                                <div className="card card-dark widget-card-1 mb-5">
                                    <div className="card-block-small status-info">
                                        <h6>Total Waste</h6>
                                        <i className="ti-trash bg-ff9b44 card1-icon"></i>
                                        <h4>{dashboard?.result.length === 0 ? 0 : ((dashboard?.result[6][0]?.TotalWeigth + 44156) / 1000).toFixed(2) + " Ton"}</h4>
                                    </div>
                                </div>
                                <div className="card card-dark widget-card-1 mb-5">
                                    <div className="card-block-small status-info">
                                        <h6>Total Emission</h6>
                                        <i className="fas fa-cloud-arrow-down bg-ff9b44 card1-icon"></i>
                                        <h4>{dashboard?.result.length === 0 ? 0 : ((dashboard?.result[4][0]?.TotalEmission) + 128053).toFixed(2)}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {role === 4 ? <div></div> : <DashboardBottomCard dashboardData={dashboard?.result} />}
                        <div className="row m-5">
                            <div className="Properties_input col-10 justify-content-center align-items-center gap-5">
                                <div className="">Properties:</div>

                                <div className="form-group">
                                    <label htmlFor="startDateInput">Start Date:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="startDateInput"
                                        placeholder="Enter Start Date"
                                        name="startDate"
                                        value={date.startDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group ">
                                    <label htmlFor="endDateInput">End Date:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="endDateInput"
                                        placeholder="Enter End Date"
                                        name="endDate"
                                        value={date.endDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="">
                                    <a href={`${urlMain}/property-pickups-data?start_date=${date.startDate}&end_date=${date.endDate}`} download>
                                        <button type="button" className="btn btn-ff9b44 btn-round"><i className="fa fa-download" aria-hidden="true"></i> Download</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </>
    )
}

export default Dashboard;