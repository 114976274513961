import { useNavigate } from "react-router-dom";
import { useDeleteSubWasteCategoryDataMutation } from "../../api/allSlice";
import swal from "sweetalert";
import moment from "moment";
import { useGetSubWasteCategoryQuery } from "./subCategorySlice";

const WasteSubCategoryExcerpt = ({ id, index, editWasteSubCategiry }) => {

    const navigate = useNavigate();

    const { subCategory, refetch } = useGetSubWasteCategoryQuery('getSubWasteCategory', {
        selectFromResult: ({ data }) => ({
            subCategory: data?.entities[id]
        }),
    })


    let deleteId = subCategory?.wc_id;
    const [deleteSubWasteCategory] = useDeleteSubWasteCategoryDataMutation({ deleteId });

    const deleteHandle = async (e) => {

        try {
            await deleteSubWasteCategory({ id: e }).unwrap();
            refetch.apply();
        } catch (error) {
            console.error('Failed to delete the category: ', error);
            swal("Erroe", "Failed to delete the category", error)
        }

    };

    return (
        <>
            <tr className="text-center fw-normal" key={index}>
                <td>{index + 1}</td>

                <td className="fw-bold">{subCategory?.wsc_name}</td>
                <td className="fw-bold">{subCategory?.wsc_measure_unit}</td>
                <td>{subCategory?.wsc_active === 1 ? <span className="badge bg-success">Active</span> : subCategory?.wsc_active === 0 ? <span className="badge bg-danger">Inactive</span> : ""}</td>
                <td>{moment(subCategory?.wsc_created_at).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                <td>
                    <div className="dropdown">
                        <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                        </div>
                        <ul className="dropdown-menu ">

                            <li className="dropdown-item ">
                                <button className=" btn " onClick={() => { editWasteSubCategiry(subCategory?.wsc_id) }}>
                                    Edit
                                </button>
                            </li>
                            <li className="dropdown-item ">
                                <button className=" btn " onClick={() => { deleteHandle(subCategory?.wsc_id) }}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>

        </>
    )
}

export default WasteSubCategoryExcerpt