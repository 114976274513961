import { useParams } from 'react-router-dom'
import { useComplainViewModel } from './ComplainController'
import { useDispatch } from 'react-redux'
import { logout } from '../auth/authSlice'
import ActionOnComplain from './ActionOnComplain'
export default function ComplainDetail() {

    const { id } = useParams()
    const { complainList,
        isComplainListLoading,
        isComplainListError,
        complainListError,
    } = useComplainViewModel()

    let complain
    const dispatch = useDispatch()
    if (!isComplainListError && id) {
        complain = complainList?.entities[id]
    }

    if (isComplainListLoading) {
        return (<p>
            Complain is loading
        </p>)
    }
    if (isComplainListError && complainListError?.statusCode === '401') {
        dispatch(logout)
    }

    if (isComplainListError && complainListError?.statusCode !== '401') {
        return (
            <p>
                Something went wrong
            </p>
        )
    }

    let complainDate = new Date(`${complain.create_at}`);


    return (
        <div className='container-fluid p-4'>
            <div className='row d-flex justify-content-between m-3'>
                <h4 className='col-5'>
                    Complain
                </h4>
                <button className='col-5 col-sm-3 col-md-2 col-lg-1 btn btn-ff9b44 btn-round'
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#actionOnComplain"
                    aria-controls="actionOnComplain"
                >Action</button>
            </div>
            <ActionOnComplain />
            <div className='row card card-body p-3'>
                <p className='col-12  '><b>Name :</b><span>{`${complain.name}`}</span>  </p>
                <p className='col-12 '><b>Number :</b><span>{`${complain.number}`}</span></p>
                <p className='col-12 '><b>Status :</b><span>{`${complain.c_status}`}</span></p>
                <p className='col-12 '><b>Text :</b><span>{`${complain.text}`}</span></p>
                <p className='col-12 '><b>Decription :</b><span>{`${complain.decription ? complain.decription : ''}`}</span></p>
                <p className='col-12 '><b>Creation Date :</b><span>{complainDate.toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    // second: "2-digit",
                    hour12: true
                })}</span></p>
            </div>
        </div>
    )
}