import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import { useGetProfileQuery } from '../../profile/profileSlice';
import { logout } from "../../auth/authSlice";
import './Navbar.css';
import { IoReorderThree } from "react-icons/io5";
import ecowraplogo from '../../../images/ecowrap-logo.png';

const NavProfile = ({ sidebarbuttonData, setSidebarbuttonData, }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({ name: "", role_type: "" })
    const handleLogout = () => {
        dispatch(logout())
    }

    const {
        data: profile,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProfileQuery('getProfile')

    useEffect(() => {
        if (isSuccess && profile && profile.ids && profile.ids.length > 0) {
            const firstProfileId = profile.ids[0];
            const firstProfile = profile.entities[firstProfileId];
            setProfileData({ name: firstProfile.name, role_type: firstProfile.role_type });
        }
    }, [isSuccess, profile])

    const SidebarButton = (e) => {
        sidebarbuttonData ? setSidebarbuttonData(false) : setSidebarbuttonData(true)
    }

    return (
        <>
            <div className="navProfileLogo">
                <ul className="navbar-collapse d-flex navbar-nav mb-2 mb-lg-0 nav nav-pills">
                    <li className="cursor-pointer nav-item ms-2 me-2">
                        <Avatar round={true} size="35" name={profileData.name} onClick={() => navigate("/b2g/profile")} />
                    </li>
                    <div className="dropdown">
                        <div className="nav-item dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="text-left user-select-all">
                                <span className="fw-bold">{profileData.name}</span>
                            </div>
                            <span className="fw-lighter">{profileData.role_type}</span>
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => navigate("/b2g/profile")}>
                                    <i className="fa-solid fa-user"></i> &nbsp; Profile
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={handleLogout}>
                                    <i className="fa-solid fa-right-from-bracket"></i> &nbsp; Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
        </>
    )
}

export default NavProfile;