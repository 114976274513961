import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";



export const wasteCategoryAdaptor = createEntityAdapter({
    selectId: (entity) => entity.wc_id,
})
const initialWasteCategory = wasteCategoryAdaptor.getInitialState()
export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWasteCategory: builder.query({
            query: () => '/b2g/eco-admin/category',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return wasteCategoryAdaptor.setAll(initialWasteCategory, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'WasteCategory', id: "List" },
                        ...result.ids.map(id => ({ type: 'WasteCategory', id }))
                    ]
                }
            }
        }),
        addNewWasteCategory: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/category',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'WasteCategory', id: 'List' }
            ]
        }),
        editWasteCategory: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/category`,
                method: 'PATCH',
                body: payLoad.body
            }),
            invalidatesTags: [
                { type: 'WasteCategory', id: 'List' }
            ]
        })

    })
})

export const {
    useGetWasteCategoryQuery,
    useAddNewWasteCategoryMutation,
    useEditWasteCategoryMutation,
} = extendedApiSlice

