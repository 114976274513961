import React, { useState, useRef } from "react";
import { useAddNewIECPersonMutation } from "./iecPersonSlice";

const AddIECPersonOffCanavas = () => {

    const [addNewIECPerson, { isLoading }] = useAddNewIECPersonMutation()
    const [addIECPerson, setAddIECPerson] = useState({ number: "", name: "" });
    const refClose = useRef(null);

    const handleChange = (e) => setAddIECPerson({ ...addIECPerson, [e.target.name]: e.target.value });

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewIECPerson({ name: addIECPerson.name, number: addIECPerson.number }).unwrap()
            setAddIECPerson({ number: "", name: "" })
            refClose.current.click()
        }
        catch (e) {
            console.error('error', e)
        }
    }

    return (
        <>
            <button type="button" className="Add_button bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New IEC Person</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add IEC Person</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={addIECPerson.name}
                                    onChange={handleChange}
                                    placeholder=""
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="number" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="number"
                                    name="number"
                                    value={addIECPerson.number}
                                    onChange={handleChange}
                                    placeholder=""
                                    autoComplete="off"
                                    required
                                />
                            </div>



                            <div className="col-md-12">
                                <button type="submit" className="btn btn-ff9b44 btn-block btn-round"
                                    onClick={handleClick}
                                    disabled={!(addIECPerson.name && addIECPerson.number)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddIECPersonOffCanavas;