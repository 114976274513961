import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";



export const subWasteCategoryAdaptor = createEntityAdapter({
    selectId: (entity) => entity.wsc_id,
})
const initialSubWasteCategory = subWasteCategoryAdaptor.getInitialState()
export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubWasteCategory: builder.query({
            query: () => '/b2g/eco-admin/subCategory',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return subWasteCategoryAdaptor.setAll(initialSubWasteCategory, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'B2GSubWasteCategory', id: "List" },
                        ...result.ids.map(id => ({ type: 'SubWasteCategory', id }))
                    ]
                }
            }
        }),
        addNewSubWasteCategory: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/subCategory',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'B2GSubWasteCategory', id: 'List' }
            ]
        }),
        editSubWasteCategory: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/subCategory`,
                method: 'PATCH',
                body: payLoad.body
            }),
            invalidatesTags: [
                { type: 'B2GSubWasteCategory', id: 'List' }
            ]
        })

    })
})

export const {
    useGetSubWasteCategoryQuery,
    useAddNewSubWasteCategoryMutation,
    useEditSubWasteCategoryMutation,
} = extendedApiSlice

