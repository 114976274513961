import { useSelector } from 'react-redux';
import B2BNavbar from '../../features/navigation_bar/b2bNavigation/b2bNavbar/AdminNavbar'
import B2BSidebar from '../../features/navigation_bar/b2bNavigation/b2bSidebar/AdminSidebar'
import { Outlet } from 'react-router-dom'

const LayoutB2B = ({ urlMain, token, adminRole }) => {
    const { button } = useSelector(state => state.nav)

    return (
        <>
            <B2BNavbar urlMain={urlMain} token={token} adminRole={adminRole} />
            <div className='d-flex overflow-auto'>
                <B2BSidebar urlMain={urlMain} token={token} adminRole={adminRole} />
                <main className='b2b-main main_box border' style={{ width: `${button ? '85vw' : '100vw'}` }}>
                    <Outlet />
                </main>
            </div>
        </>
    );
};
export default LayoutB2B;