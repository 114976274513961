import React, { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import AddWasteSubCategoryOffCanvas from "./AddWasteSubCategoryOffCanvas";
import WasteSubCategoryExcerpt from "./WasteSubCategoryExcerpt";
import MainBreadcrumb from "../../../components/breadcrumb/MainBreadcrumb";
import EditWasteSubCategoryOffCanvas from "./EditWasteSubCategoryOffCanvas";
import { useGetSubWasteCategoryQuery } from "./subCategorySlice";

const WasteSubCategoryList = () => {

    const [status, setStatus] = useState("");
    const [editWasteSubCategiryState, setEditWasteSubCategiryState] = useState({})
    const location = useLocation().state;
    const refOpen = useRef(null);

    const {
        data: subCategory,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSubWasteCategoryQuery('getSubWasteCategory')
    console.log(subCategory);


    let filterdata = subCategory?.ids?.filter((e, i) => {
        return (
            subCategory?.entities[e].wsc_wc_id == location
        )
    }
    );
    const handleReset = () => window.location.reload();

    const editWasteSubCategiry = (wsc_id) => {
        const subData = subCategory?.entities[wsc_id]
        setEditWasteSubCategiryState({ wsc_id: wsc_id, wsc_wc_id: location, wsc_active: subData.wsc_active, wsc_name: subData.wsc_name, wsc_measure_unit: subData.wsc_measure_unit })
        refOpen.current.click()
    }


    return (
        <>
            {
                // loadingPage ? <SpinnerForPage /> :
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h3 className="page-title">Sub Category List</h3>
                            <ul className="breadcrumb">
                                <MainBreadcrumb />

                            </ul>
                        </div>
                        <div className="col-md-6 text-right">
                            <AddWasteSubCategoryOffCanvas wsc_wc_id={location} />
                        </div>
                    </div>
                    <form>
                        <div className="row mt-4">
                            <div style={{ height: "40px" }} className="col-md-12  d-flex justify-content-center gap-2">


                                <select className="form-select w-25" id="floatingInput" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select Status </option>
                                    <option value="0">Active</option>
                                    <option value="1">Inactive</option>
                                </select>
                                <button type="button" className="bg-left-navabr  text-light  btn btn-secondary " >
                                    Search
                                </button>
                                <button type="reset" className="bg-left-navabr  text-light  btn btn-secondary" onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="card card-dark mt-4" >
                        {

                            (
                                <div className="table-border-style">
                                    <div className="table-responsive" style={{ height: "60vh " }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Weight Type</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Create Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    filterdata?.map((id, index) => {
                                                        return (

                                                            <WasteSubCategoryExcerpt id={id} index={index} editWasteSubCategiry={editWasteSubCategiry} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            }
            <EditWasteSubCategoryOffCanvas setEditWasteSubCategiryState={setEditWasteSubCategiryState} refOpen={refOpen} editWasteSubCategiryState={editWasteSubCategiryState} />

        </>
    )
}

export default WasteSubCategoryList; 