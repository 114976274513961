import { useNavigate } from "react-router-dom"; 
import moment from "moment";
import swal from "sweetalert"
import { useGetWasteCategoryQuery } from "./categorySlice";
import { useDeleteWasteCategoryDataMutation } from "../../api/allSlice";


const WasteCategoryExcerpt = ({ editWasteCategory, id, index }) => {
    const navigate = useNavigate();

    // Fetch the category data using the existing query
    const { category, refetch } = useGetWasteCategoryQuery('getWasteCategory', {
        selectFromResult: ({ data }) => ({
            category: data?.entities[id]
        }),
    });

    let deleteId = category?.wc_id;
    const [deleteWasteCategory] = useDeleteWasteCategoryDataMutation({ deleteId });

    const deleteHandle = async (e) => {

        try {
            await deleteWasteCategory({ id: e }).unwrap();
            refetch.apply();

        } catch (error) {
            console.error('Failed to delete the category: ', error);
            swal("Erroe", "Failed to delete the category", error)
        }

    };

    return (
        <>
            <tr className="search_button text-center fw-normal" key={index}>
                <td>{index + 1}</td>

                <td className="fw-bold">{category?.wc_name}</td>
                <td>
                    {category?.wc_active === 1 ? (
                        <span className="badge bg-success">Active</span>
                    ) : category?.wc_active === 0 ? (
                        <span className="badge bg-danger">Inactive</span>
                    ) : ""}
                </td>
                <td>
                    {moment(category?.wc_created_at).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                </td>
                <td>
                    <div className="dropdown">
                        <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => navigate("/b2g/subcategory", { state: category?.wc_id })}>
                                    Sub Category
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => { editWasteCategory(category?.wc_id) }} >
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => { deleteHandle(category?.wc_id) }}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Category Name :-</span>
                        <span className="Card_data_list">{category?.wc_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Status :-</span>
                        <span className="Card_data_list">
                            {category?.wc_active === 1 ? (
                                <span className="badge bg-success">Active</span>
                            ) : category?.wc_active === 0 ? (
                                <span className="badge bg-danger">Inactive</span>
                            ) : ""}
                        </span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Create Date :-</span>
                        <span className="Card_data_list"> {moment(category?.wc_created_at).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Action:-</span>
                        <span className="Card_data_list">
                            <div className="dropdown">
                                <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Action
                                </div>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button className="dropdown-item" onClick={() => navigate("/b2g/subcategory", { state: category?.wc_id })}>
                                            Sub Category
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={() => { editWasteCategory(category?.wc_id) }} >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={() => { deleteHandle(category?.wc_id) }}>
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WasteCategoryExcerpt;
