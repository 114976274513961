import { useComplainViewModel } from "./ComplainController";
import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../auth/authSlice";

export default function ActionOnComplain() {

    const { id } = useParams()
    const ref = useRef(null)
    const dispatch = useDispatch()
    const {
        statusList,
        isStatusListLoading,
        isStatusListError,
        statusListError,
        handleChangeComplainStatus,
        handleClickComplainStatus,
        isChangeStatusLoading,
        isChangeStatusError,
        changeStatusError,
        patchComplainDetail,
        setPatchComplainDetail
    } = useComplainViewModel()

    if (isChangeStatusError && changeStatusError?.statusCode === '401') {
        dispatch(logout)
    }

    useEffect(() => {
        if (id) setPatchComplainDetail((pre) => ({ ...pre, c_id: id }))
    }, [id, setPatchComplainDetail])
    return (
        <div className="offcanvas offcanvas-end" tabindex="-1" id="actionOnComplain" aria-labelledby="offcanvasLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasLabel">Action </h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                    ref={ref}></button>
            </div>
            <div className="offcanvas-body d-flex flex-column justify-content-start mb-5">
                <select
                    className="form-select mb-5"
                    aria-label="complain status"
                    name="c_status"
                    value={patchComplainDetail.c_status}
                    onChange={handleChangeComplainStatus}>
                    <option selected>--</option>
                    {
                        !isStatusListLoading && !isStatusListError && statusList.map(item => {
                            return <option value={item}>{item}</option>
                        })
                    }
                </select>
                <button className="btn btn-ff9b44 btn-round"
                    onClick={() => (handleClickComplainStatus(ref, { c_id: id, c_status: patchComplainDetail.c_status }))}
                    disabled={isChangeStatusLoading}>Submit</button>
            </div>
        </div>
    )
}