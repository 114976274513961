import { apiSlice } from "./apiSlice";
import { adapter } from "./entityAdapter";

const initialCategory = adapter.category.getInitialState();
const initialSubCategory = adapter.subCategory.getInitialState();
const initialDailyWaste = adapter.dailyWaste.getInitialState();



const getData = (adapter, initialState, queryPath, type) => ({
    query: () => queryPath,
    transformResponse: responseData => {
        if (responseData?.status === false) {
            throw new Error(responseData?.statusCode);
        }
        return adapter.setAll(initialState, responseData?.data);
    },
    providesTags: (result, error, arg) => {
        if (error || !result?.ids) {
            return [{ type, id: 'List' }];
        } else {
            return result.ids.map(id => ({ type, id }));
        }
    }
});

const addData = (urlPath, type) => ({
    query: body => ({
        url: urlPath,
        method: 'POST',
        body: body
    }),
    invalidatesTags: [
        { type, id: 'List' }
    ]
});

const deleteData = (urlPath, type) => ({
    query: ({ id }) => ({
        url: `${urlPath}/${id}?is_deleted=1`,
        method: 'DELETE',
    }),
    invalidatesTags: [
        { type, id: 'List' }
    ]
});



const editData = (urlPath, type) => ({
    query: payLoad => ({
        url: urlPath,
        method: 'PATCH',
        body: payLoad.body
    }),
    invalidatesTags: [
        { type, id: 'List' }
    ]
})




export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        // Waste Category Data
        deleteWasteCategoryData: builder.mutation(deleteData('/b2g/eco-admin/category', 'B2gCategoryList')),

        // Waste Sub Category Data
        deleteSubWasteCategoryData: builder.mutation(deleteData('/b2g/eco-admin/subCategory', 'B2gSubCategory')),


        // Daily Waste Data
        deleteDailyWasteData: builder.mutation(deleteData('/b2g/eco-admin/waste', 'B2gDailyWaste')),



    })
});

export const {



    useDeleteWasteCategoryDataMutation,

    useDeleteSubWasteCategoryDataMutation,

    useDeleteDailyWasteDataMutation,




} = extendedApiSlice;