import React, { useRef } from 'react'
import { useEditSubWasteCategoryMutation } from './subCategorySlice';

const EditWasteSubCategoryOffCanvas = ({ setEditWasteSubCategiryState, refOpen, editWasteSubCategiryState }) => {


    const refClose = useRef(null);
    const [editDailyWasteData, { loading }] = useEditSubWasteCategoryMutation()


    const changeHandle = (e) => {
        setEditWasteSubCategiryState({ ...editWasteSubCategiryState, [e.target.name]: e.target.value })
    }


    let buttonDisbale = false;
    const handleClick = async (e) => {
        buttonDisbale = true
        e.preventDefault()
        try {

            const editData = {
                "wsc_name": editWasteSubCategiryState.wsc_name,
                "wsc_wc_id": editWasteSubCategiryState.wsc_wc_id,
               "wsc_active": editWasteSubCategiryState.wsc_active === 1 ? '1' : '0',
                "wsc_measure_unit": editWasteSubCategiryState.wsc_measure_unit,
                "wsc_id": editWasteSubCategiryState.wsc_id
            }

            console.log(editData);


            await editDailyWasteData({ body: editData }).unwrap()
            console.log("error");
            refClose.current.click()
        }
        catch (e) {
            console.error("Error in Daily Waste edit ->", e)
        } finally {
            buttonDisbale = false;
        }

    }


    return (
        <>

            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Waste Sub Category</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold ">
                                    Product name
                                </label>
                                <input
                                    onChange={changeHandle}
                                    value={editWasteSubCategiryState.wsc_name}
                                    className="form-control"
                                    type='text'
                                    name='wsc_name'
                                />

                                <label htmlFor="name" className="form-label font-weight-bold mt-4">
                                    Weight Type
                                </label>
                                <select onChange={changeHandle} className="form-select" name="wsc_measure_unit" value={editWasteSubCategiryState.wsc_measure_unit}>
                                    <option value="">Select Weight Type</option>
                                    <option value="kg">Kilograms</option>
                                    <option value="litre">Litre</option>
                                    <option value="ton">Ton</option>
                                    <option value="box">Box</option>
                                </select>

                                <label htmlFor="name" className="form-label font-weight-bold mt-4">
                                    Status
                                </label>
                                <select onChange={changeHandle} className="form-select" name="wsc_active" value={editWasteSubCategiryState.wsc_active}>
                                    <option value="">Select Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In Active</option>
                                </select>

                            </div>



                            <div className="col-md-12">
                                <button type="submit" disabled={buttonDisbale} className="btn btn-secondary w-100 btn-block rounded-pill mt-4" onClick={handleClick}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EditWasteSubCategoryOffCanvas