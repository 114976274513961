import { createSlice } from "@reduxjs/toolkit";

const initialState = { page: 1 }

const collectionSlice = createSlice({
    name: 'collectionB2g',
    initialState,
    reducers: {
        setPageGlobal(state, action) {
            state.page = action.payload.page;
        },
    }
})

export default collectionSlice.reducer
export const { setPageGlobal } = collectionSlice.actions