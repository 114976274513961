import { searchParamsUpdate } from "./ComplainSlice";
import { useGetComplainListQuery, useGetComplainStatusQuery, useChangeComplainStatusMutation } from "./ComplainRtkQuery";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";


export function useComplainViewModel() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchParamsGlobal = useSelector((state) => {
        return state.complainB2g
    })

    const [searchParamsLocal, setSearchParamsLocal] = useState(searchParamsGlobal || '')

    const [patchComplainDetail, setPatchComplainDetail] = useState({ c_status: '', c_id: '' })

    const { data: complainList, isLoading: isComplainListLoading, isError: isComplainListError, error: complainListError } = useGetComplainListQuery(searchParamsGlobal);

    const { data: statusList, isLoading: isStatusListLoading, isError: isStatusListError, error: statusListError } = useGetComplainStatusQuery();

    const [changeComplainStatus, { isLoading: isChangeStatusLoading, isError: isChangeStatusError, error: changeStatusError }] = useChangeComplainStatusMutation();


    function handleChangeComplainStatus(e) {
        const { name, value } = e.target
        setPatchComplainDetail({ ...patchComplainDetail, [name]: value })
    }

    function handleClickComplainStatus(ref, payload) {
        ref.current.click()
        changeComplainStatus(payload)
        if (isChangeStatusError && changeStatusError?.statusCode !== 401) {
            swal({
                title: "Oops...",
                text: "Something went wrong!",
                icon: "error",
                button: "Ok",
            })
        }
    }
    function handleChange(e) {
        const { name, value } = e.target
        setSearchParamsLocal({ ...searchParamsLocal, [name]: value })
    }
    function handleSearch() {
        dispatch(searchParamsUpdate(searchParamsLocal))
    }
    function handleReset() {
        setSearchParamsLocal({ status: "" })
        dispatch(searchParamsUpdate({ status: "" }))
    }
    function handleNavigateToDetail(id) {
        navigate(`/b2g/complains/${id}`)
    }
    useEffect(() => {
        setSearchParamsLocal(searchParamsGlobal);
    }, [searchParamsGlobal]);
    return {
        handleReset,
        handleChange,
        handleSearch,
        handleNavigateToDetail,
        complainList,
        isComplainListLoading,
        isComplainListError,
        complainListError,
        statusList,
        isStatusListLoading,
        isStatusListError,
        statusListError,
        searchParamsLocal,
        handleChangeComplainStatus,
        handleClickComplainStatus,
        isChangeStatusLoading,
        isChangeStatusError,
        changeStatusError,
        patchComplainDetail,
        setPatchComplainDetail
        // selectAllComplain
    }
}