import React, { useState, useRef } from "react";
import { useAddNewSubWasteCategoryMutation } from "./subCategorySlice";

const AddWasteSubCategoryOffCanvas = ({ wsc_wc_id }) => {


    const [addNewSubCategory, { isLoading }] = useAddNewSubWasteCategoryMutation()
    const [addSubCategory, setAddSubCategory] = useState({ wsc_name: "", wsc_measure_unit: '' });
    const refClose = useRef(null);

    const handleChange = (e) => setAddSubCategory({ ...addSubCategory, [e.target.name]: e.target.value });




    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewSubCategory({ 'wsc_name': addSubCategory.wsc_name, 'wsc_wc_id': wsc_wc_id, 'wsc_measure_unit': addSubCategory.wsc_measure_unit }).unwrap()
            setAddSubCategory({ wsc_name: "", wsc_measure_unit: '' })
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }
    return (
        <>
            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Sub Category</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Sub Category</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="wsc_name"
                                    value={addSubCategory.wsc_name}
                                    onChange={handleChange}
                                    placeholder="Ex: Glass"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Weight Type
                                </label>
                                <select className="form-select" name="wsc_measure_unit" onChange={handleChange} value={addSubCategory.wsc_measure_unit} required>
                                    <option value="">Select Weight Type</option>
                                    <option value="kg">Kilograms</option>
                                    <option value="litre">Litre</option>
                                    <option value="ton">ton</option>
                                    <option value="box">box</option>
                                </select>

                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                    disabled={!(addSubCategory.wsc_name)}>
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddWasteSubCategoryOffCanvas;