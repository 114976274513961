import { useParams } from "react-router-dom";
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import { useGetIecTeamQuery } from "./iecTeamSlice";
import { useState, useEffect } from "react";
import moment from "moment";

const IECTeamDetail = () => {
    const { id, page, index } = useParams();
    const [size, setSize] = useState(false);
    const [rotate_image, setRotate_image] = useState(false);

    const { iec } = useGetIecTeamQuery({ page }, {
        selectFromResult: ({ data }) => ({
            iec: data?.entities[(id - 1 * (page * 10 - 9))]
        }),
    });

    const [abc, setAbc] = useState([]);
    const [rotationState, setRotationState] = useState({});
    const [rotatecount, setrotatecount] = useState(0)

    useEffect(() => {

        if (iec && iec.image_urls) {
            try {
                const parsedImageUrls = JSON.parse(iec.image_urls);
                setAbc(parsedImageUrls);
            } catch (error) {
                console.error("Error parsing image_urls:", error);
            }
        }
    }, [iec]);


    const Zoomout_image = () => {
        if (size == true) {
            const allImages = document.querySelectorAll('.mix-empty-img');
            allImages.forEach((img) => {
                img.classList.remove('mix-empty-img-enlar');
                img.style.transform = 'none';

            });
            setSize(false)
        }
    }

    const imgZoomToggle = (e) => {
        const allImages = document.querySelectorAll('.mix-empty-img');
        const clickedElement = e.currentTarget;
        const isAlreadyEnlarged = clickedElement.classList.contains('mix-empty-img-enlar');
        setSize(true)

        // allImages.forEach((img) => {
        //     img.classList.remove('mix-empty-img-enlar');
        //     img.style.transform = 'none';
        // });

        if (!isAlreadyEnlarged) {
            clickedElement.classList.add('mix-empty-img-enlar');

            const rotation = rotationState[clickedElement.id] || 0;
            clickedElement.style.transform = `rotate(${rotation}deg) scale(2)`;


        } else {
            setRotationState({ [clickedElement.id]: 0 })
            setrotatecount(0)
        }
        Zoomout_image()
    };

    const rotateImage = (e, imgId) => {

        const image = document.getElementById(imgId);
        if (image && image.classList.contains('mix-empty-img-enlar')) {
            let currentRotation = 0
            currentRotation = rotationState[imgId] || 0;
            const newRotation = currentRotation + 90;

            setRotationState(prevState => ({
                ...prevState,
                [imgId]: newRotation
            }));
            if (rotatecount % 2 == 0) {
                image.style.transform = `rotate(${newRotation}deg) scale(2,4)`;

            } else {
                image.style.transform = `rotate(${newRotation}deg) scale(2)`;

            }
            setrotatecount(rotatecount + 1)
        }
    };


    return (
        <>

            <div className="container-fluid mt-5 sidebarhome" style={{ height: '100vh', paddingBottom: '150px' }}>
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h6 className="page-title">IEC Team Person Details</h6>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />
                        </ul>
                    </div>
                </div>

                <div onClick={Zoomout_image} className="card card-dark w-100 p-4" style={{ borderRadius: '20px' }}>
                    <h3 className="mb-3">IEC Team Person Details</h3>
                    <div className="row " >
                        <h6 className="col-md-4 mb-4   ">Name : {iec?.name}</h6>
                        <h6 className="col-md-4 mb-4 ">Number : {iec?.number}</h6>
                        {(iec?.vehicle_number == (null || '') && <h6 className="col-md-4 mb-4 ">Vehicle Number : NA </h6>) || <h6 className="col-md-4 mb-4">Vehicle Number : {iec?.vehicle_number}</h6>}
                        <h6 className="col-md-4 mb-4 ">Reference : {iec?.reference}</h6>
                        <h6 className="col-md-4 mb-4 ">Shirt Size : {iec?.shirt_size}</h6>
                        {(iec?.license_number == (null || '') && <h6 className="col-md-4 mb-4 ">License Number : NA </h6>) || <h6 className="col-md-4 mb-4">License Number : {iec?.license_number}</h6>}
                        <h6 className="col-md-4 mb-4 ">Emergency Contact Name : {iec?.emr_contact_name}</h6>
                        {(iec?.pf_number == (null || '') && <h6 className="col-md-4 mb-4 ">PF Number : NA </h6>) || <h6 className="col-md-4 mb-4 ">PF Number : {iec?.pf_number}</h6>}
                        <h6 className="col-md-4 mb-4 ">Emergency Contact Number : {iec?.emr_contact_number}</h6>
                        <h6 className="col-md-4 mb-4">Email : {iec?.email}</h6>
                        <h6 className="col-md-4 mb-4 ">Locality : {iec?.locality}</h6>
                        <h6 className="col-md-4 mb-4">Joining Date : {iec?.joining_date}</h6>
                        <h6 className="col-md-4 mb-4">Create Date : {moment(iec?.create_at).format("DD/MM/YYYY").toLocaleString()}</h6>

                    </div>
                </div>

                <div className="card card-dark mt-5" style={{ borderRadius: '20px' }}>
                    <div className="d-flex justify-content-around flex-wrap w-100 gap-4 p-3">
                        {abc?.map((e, i) => (
                            <div className="card col-3" style={{ borderRadius: '20px' }} key={i}>
                                <div className='ms-4 me-4 mb-3 p-3'>
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>

                                        <img
                                            className='mix-empty-img'
                                            src={`https://apis.ecowrap.in/iec-team/${e}`}
                                            id={`docImg${i + 1}`}
                                            onClick={imgZoomToggle}
                                        />
                                        <button className="rotate-button btn-dark" onClick={(event) => rotateImage(event, `docImg${i + 1}`)} > Rotate Image </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IECTeamDetail;
