import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import { useGetProfileQuery } from '../../profile/profileSlice';
import { logout } from "../../auth/authSlice";
import './Navbar.css';
import { IoReorderThree } from "react-icons/io5";
import ecowraplogo from '../../../images/ecowrap-logo.png';
import NavProfile from "./NavProfile";

const B2GNavbar = ({ sidebarbuttonData, setSidebarbuttonData, }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({ name: "", role_type: "" })
    const handleLogout = () => {
        dispatch(logout())
    }

    const {
        data: profile,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProfileQuery('getProfile')

    useEffect(() => {
        if (isSuccess && profile && profile.ids && profile.ids.length > 0) {
            const firstProfileId = profile.ids[0];
            const firstProfile = profile.entities[firstProfileId];
            setProfileData({ name: firstProfile.name, role_type: firstProfile.role_type });
        }
    }, [isSuccess, profile])

    const SidebarButton = (e) => {
        sidebarbuttonData ? setSidebarbuttonData(false) : setSidebarbuttonData(true)
    }

    return (
        <>
            <nav className={` navbar-b2g navbar navbar-expand-lg ${secureLocalStorage.getItem("selectedTheme") === "dark" ? "bg-top-navbar-dark" : "bg-top-navbar-light"}`}>
                <div className=" container-fluid">
                    <div className="btn cursor-pointer nav_button">
                        <IoReorderThree onClick={SidebarButton} />
                    </div>
                    <div className="navbar-brand ecowrap_logo">
                        <a href="/">
                            <img className="Navbar_logo" src={ecowraplogo} alt="ecowrap-logo" />
                        </a>
                    </div>
                    <NavProfile />
                </div>
            </nav>

        </>
    )
}

export default B2GNavbar;