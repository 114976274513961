import React, { useRef } from 'react'
import { useEditWasteCategoryMutation } from './categorySlice';

const EditWasteCategoryOffCanvas = ({ refOpen, setStateEditWasteCategory, stateEditWasteCategory }) => {

    const refClose = useRef(null);
    const [editDailyWasteData, { loading }] = useEditWasteCategoryMutation()


    const changeHandle = (e) => {
        setStateEditWasteCategory({ ...stateEditWasteCategory, [e.target.name]: e.target.value })
    }


    let buttonDisbale = false;
    const handleClick = async (e) => {
        buttonDisbale = true
        e.preventDefault()
        try {

            const editData = {
                "wc_name": stateEditWasteCategory.wc_name,
                "wc_id": stateEditWasteCategory.wc_id,
                "wc_active": stateEditWasteCategory.wc_active
            }

            await editDailyWasteData({ body: editData }).unwrap()
            setStateEditWasteCategory({})
            refClose.current.click()
        }
        catch (e) {
            console.error("Error in Daily Waste edit ->", e)
        } finally {
            buttonDisbale = false;
        }

    }


    return (
        <>

            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Waste  Category</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold ">
                                    Category name
                                </label>
                                <input
                                    onChange={changeHandle}
                                    value={stateEditWasteCategory?.wc_name}
                                    className="form-control"
                                    type='text'
                                    name='wc_name'
                                />


                                <label htmlFor="name" className="form-label font-weight-bold mt-4">
                                    Status
                                </label>
                                <select onChange={changeHandle} className="form-select" name="wc_active" value={stateEditWasteCategory?.wc_active}>
                                    <option value="">Select Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In Active</option>
                                </select>

                            </div>

                            <div className="col-md-12">
                                <button type="submit" disabled={buttonDisbale} className="btn btn-secondary w-100 btn-block rounded-pill mt-4" onClick={handleClick}>
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EditWasteCategoryOffCanvas