import { useGetCollectionListQuery } from './collectionRtk'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPageGlobal } from './collectionSlice'
import { useSelector } from 'react-redux'

export function useCollectionController() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const collectionStateGlobal = useSelector((state) => (state.collectionB2g))
    const [page, setPage] = useState(collectionStateGlobal.page)

    const { data: collectionList, isError: isErrorCollectionList, error: errorCollectionList, isLoading: isLoadingCollectionList } = useGetCollectionListQuery(collectionStateGlobal.page)

    function handleNavigate(url) {
        navigate(url)
    }


    useEffect(() => {
        dispatch(setPageGlobal({ page: page }))
    }, [page, dispatch])

    return {
        page,
        setPage,
        collectionList,
        isErrorCollectionList,
        errorCollectionList,
        isLoadingCollectionList,
        handleNavigate
    }


}