import React from 'react'

function Pagination({ page, firstPageClick, previousPageClick, data, nextPageClick }) {
    return (
        <div>
            <div className="container mt-3 mb-3" id="paginationnumbers">
                <div className="pagination" id="paginationnumbers">
                    <div className="page-item text-left">
                        <button className="page-link" disabled={page === '1'} onClick={firstPageClick}>
                            1
                        </button>
                    </div>
                    <div className="page-item text-left">
                        <button className="page-link" disabled={page === '1'} onClick={previousPageClick}>
                            &laquo; Previous
                        </button>
                    </div>
                    <div className="page-item">
                        <div className="page-link">
                            {page} of {data?.totalPages}
                        </div>
                    </div>
                    <div className="page-item text-left">
                        <button className="page-link" disabled={page === data?.totalPages} onClick={nextPageClick}>
                            Next &raquo;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination
