import { createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../api/apiSlice"


const collectionAdaptor = createEntityAdapter({
    selectId: (item) => (item.assignment_id)
})

const initialCollection = collectionAdaptor.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCollectionList: builder.query({
            query: (page) => {
                let url = `/b2g/eco-admin/collection?page=${page}`
                return url
            },
            transformErrorResponse: (err) => {
                return err
            },
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    const { totalCollections, totalPages, currentPage, data } = response.data[0];
                    return {
                        totalCollections,
                        totalPages,
                        currentPage,
                        entities: data
                    }
                } else {
                    throw new Error(response.data)
                }
            },
            providesTags: (result, error, args) => {
                if (error) {
                    return []
                }
                if (!error && result) {
                    return [{ type: 'collectionB2g', id: 'List' },]
                }
            }
        }),

        getCollectionDetails: builder.query({
            query: (searchParams) => {
                const arr = []
                let url = `/b2g/eco-admin/collection`
                if (searchParams && searchParams.collection_date) {
                    arr.push(`assignment_last_update=${searchParams.collection_date}`)
                }
                if (searchParams && searchParams.assignment_id) {
                    url = url + `/${searchParams.assignment_id}`
                }
                if (arr.length > 0) {
                    url = url + '?' + arr.join('&')
                }
                return url
            },

            transformErrorResponse: (error) => {
                return error
            },
            
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    return response.data
                } else {
                    throw new Error(response)
                }
            },
            
            providesTags: ['collectionDetails', 'List']
        })
    })
})

// export const { selectAll: selectAllComplain } = complainAdaptor.getSelectors((state) => state.complaints);

export const { useGetCollectionListQuery, useGetCollectionDetailsQuery } = extendedApiSlice