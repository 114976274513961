import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { useEditDailyWasteMutation } from './dailyWasteSlice';

const EditDailyWaste = ({ subCategory, refOpen, stateEditDailyWaste, setStateEditDailyWaste, wasteId }) => {

    const [buttonDisbale, setButtonDisabled] = useState(false);
    const [weightData, setWeightData] = useState({})
    const [editDailyWasteData, { isLoading }] = useEditDailyWasteMutation()
    const refClose = useRef(null);

    const handleClick = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        try {
            const wasteData = Object.entries(stateEditDailyWaste?.waste);
            const wasteDataNumber = wasteData.map((item) => item.map((subArr) => Number(subArr)));
            const editData = {
                "w_id": wasteId,
                "waste": JSON.stringify(wasteDataNumber),
                "collection_date": moment(stateEditDailyWaste.collection_date).format("YYYY-MM-DD")
            };

            await editDailyWasteData({ body: editData }).unwrap();
            let abcde = Object.assign({}, subCategory?.ids?.reduce((acc, e) => { acc[e] = null; return acc; }, {}))
            setStateEditDailyWaste({ waste: abcde, collection_date: null })

            refClose.current.click();
        } catch (e) {
            console.error("Error in Daily Waste edit ->", e);
        } finally {
            setButtonDisabled(false);
        }

    };

    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Daily Waste</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Collection Date
                                </label>
                                <input
                                    value={moment(stateEditDailyWaste?.collection_date).format("YYYY-MM-DD")}
                                    onChange={(e) => { setStateEditDailyWaste({ ...stateEditDailyWaste, [e.target.name]: e.target.value }) }}
                                    className="form-control"
                                    type='date'
                                    name='collection_date'
                                />

                            </div>
                            <div className="col-md-12 text-left d-flex justify-content-between">
                                <div className="col-md-4 ">
                                    <label htmlFor="name" className="form-label font-weight-bold">
                                        Category
                                    </label>

                                </div>
                                <div className="col-md-4 ">
                                    <label htmlFor="name" className="form-label font-weight-bold">
                                        Collection Weight
                                    </label>

                                </div>

                            </div>
                            {stateEditDailyWaste &&
                                subCategory?.ids?.map((id, i) => {
                                    return (
                                        <div className="col-md-12 text-left d-flex justify-content-between">
                                            <div className="col-md-6 ">
                                                <input onBeforeInput={(e) => {
                                                }} name={id} className="form-control" type="text" value={`${subCategory?.entities[id].wsc_name} (in ${subCategory?.entities[id].wsc_measure_unit})`} disabled ></input>

                                            </div>
                                            <div className="col-md-5 ">
                                                <input onChange={(e) => { setStateEditDailyWaste({ ...stateEditDailyWaste, waste: { ...stateEditDailyWaste.waste, [id]: e.target.value } }) }} value={stateEditDailyWaste?.waste[id] || ''} type="number" className="form-control" />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="col-md-12">
                                <button type="submit" disabled={buttonDisbale} className="btn btn-secondary w-100 btn-block rounded-pill" onClick={handleClick}>
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EditDailyWaste