import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const iecTeamAdaptor = createEntityAdapter({
    selectId: (entity) => entity.id,
})
const initialIecTeam = iecTeamAdaptor.getInitialState()




export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIecTeam: builder.query({
            query: ({ page }) => `b2g/eco-admin/iec-team?page=${page}`,
            transformResponse: responseData => {
                const { totalProperties, totalPage, data } = responseData?.data 
                return { totalProperties, totalPage, entities: data };
            },

            providesTags: (result, error, arg) => { 
                if (error) {
                    return []
                }
                if (result?.entities) {
                    return [
                        { type: 'iecTeam', id: 'List' },
                        ...result.entities.map((iec) => ({ type: 'iecTeam', id: iec.id })),
                    ];
                }
            }
        }),

    })
})

export const {
    useGetIecTeamQuery,

} = extendedApiSlice

// 1998