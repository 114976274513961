import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';

const AddGeofence = ({ urlB2G, wardData }) => {
    const location = useLocation();
    const ref = useRef(null);
    const navigate = useNavigate();

    if (!wardData.wardId) {
        navigate('/b2g/wards');
    }

    const [polygons, setPolygons] = useState([]);
    const [newPolygon, setNewPolygon] = useState([]);
    const [polygonHistory, setPolygonHistory] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapsInstance, setMapsInstance] = useState(null);
    const [polygonShapes, setPolygonShapes] = useState([]);
    const [newPolygonShape, setNewPolygonShape] = useState(null);
    const [areaName, setAreaName] = useState(wardData.wardNumber + ' ' + wardData.wardCommunity);
    const [tag, setTag] = useState('');
    const [center, setCenter] = useState({ lat: 26.8495, lng: 75.7575 });
    const [selectedType, setSelectedType] = useState('boundary');
    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchBoundaries = async () => {
            try {
                const response = await fetch(`${urlB2G}/address/community/ward/geo-fance`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${authState?.token}`,
                    },
                });
                const data = await response.json();

                if (data?.status && data?.data?.length > 0) {
                    const polygonsData = data?.data.map((geofence) => ({
                        id: geofence?.geo_id,
                        bounds: geofence?.boundry[0]?.map((point) => ({ lat: point?.x, lng: point?.y })),
                        areaName: geofence?.area_name,
                        tag: geofence?.tag,
                    }));

                    setPolygons(polygonsData);
                } else {
                    console.error('Something went wrong');
                }
            } catch (error) {
                console.error('Error fetching boundary data:', error);
            }
        };

        fetchBoundaries();
    }, [urlB2G, authState?.token]);

    const updateNewPolygon = () => {
        if (newPolygonShape) {
            const updatedPath = newPolygonShape.getPath().getArray().map((coord) => ({
                lat: coord.lat(),
                lng: coord.lng(),
            }));
            setPolygonHistory((prevHistory) => [...prevHistory, newPolygon]);
            setNewPolygon(updatedPath);
        }
    };

    const handleUndo = () => {
        if (polygonHistory.length > 0) {
            const previousPolygon = polygonHistory[polygonHistory.length - 1];
            setPolygonHistory((prevHistory) => prevHistory.slice(0, prevHistory.length - 1));
            setNewPolygon(previousPolygon);
            newPolygonShape.setPath(previousPolygon.map((point) => new mapsInstance.LatLng(point.lat, point.lng)));
        }
    };

    useEffect(() => {
        if (mapInstance && mapsInstance) {
            polygonShapes.forEach((shape) => shape.setMap(null));
            setPolygonShapes([]);

            const newPolygonShapes = polygons.map((polygonData) => {
                const path = polygonData.bounds.map((point) => new mapsInstance.LatLng(point.lat, point.lng));

                const polygonShape = new mapsInstance.Polygon({
                    paths: path,
                    draggable: false,
                    editable: false,
                    fillColor: 'gray',
                    fillOpacity: 0.2,
                    strokeColor: 'gray',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                });

                polygonShape.setMap(mapInstance);
                return polygonShape;
            });

            setPolygonShapes(newPolygonShapes);

            if (newPolygon.length > 0) {
                const path = newPolygon.map((point) => new mapsInstance.LatLng(point.lat, point.lng));

                if (!newPolygonShape) {
                    const newShape = new mapsInstance.Polygon({
                        paths: path,
                        draggable: true,
                        editable: true,
                        fillColor: 'lightblue',
                        fillOpacity: 0.5,
                        strokeColor: 'blue',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                    });

                    newShape.setMap(mapInstance);
                    const polygonPath = newShape.getPath();

                    mapsInstance.event.addListener(polygonPath, 'set_at', updateNewPolygon);
                    mapsInstance.event.addListener(polygonPath, 'insert_at', updateNewPolygon);
                    mapsInstance.event.addListener(polygonPath, 'remove_at', updateNewPolygon);
                    mapsInstance.event.addListener(newShape, 'dragend', updateNewPolygon);

                    setNewPolygonShape(newShape);
                } else {
                    newPolygonShape.setPath(path);
                }
            }
        }
    }, [polygons, mapInstance, mapsInstance, newPolygon, newPolygonShape]);

    const handleSubmit = async () => {
        let coordinates;
        if (newPolygonShape) {
            const updatedPath = newPolygonShape.getPath().getArray().map((coord) => ({
                lat: coord.lat(),
                lng: coord.lng(),
            }));
            coordinates = [...updatedPath, updatedPath[0]]; 
            setNewPolygon(updatedPath);
        }

        if (Array.isArray(coordinates)) {
            coordinates = coordinates.map((point) => `${point.lat} ${point.lng}`).join(', ');
        }

        const requestBody = {
            coordinates,
            area_name: areaName,
            ward_id: wardData.wardId,
            tag,
            type: selectedType,
        }; 
        
        try {
            const response = await fetch(`${urlB2G}/address/community/ward/geo-fance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState?.token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                navigate('/b2g/geofanceTable');
            } else {
                console.error('Error adding geofence:', response);
            }
        } catch (error) {
            console.error('Error adding geofence:', error);
        }
    };

    return (
        <div className="row mt-4">
            <div className="col-md-12 d-flex">
                <div className="form-group col-3 m-2">
                    <label>Area Name</label>
                    <input type="text" className="form-control" value={areaName} onChange={(e) => setAreaName(e.target.value)} />
                </div>
                <div className="form-group col-2 m-2">
                    <label>Tag</label>
                    <input type="text" className="form-control" value={tag} onChange={(e) => setTag(e.target.value)} />
                </div>
                <div className="form-group col-2 m-2">
                    <label>Type</label>
                    <select className="form-control" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <option value="boundary">Boundary</option>
                        <option value="subArea">Sub Area</option>
                    </select>
                </div>
                <div className="d-flex col-2 p-3 justify-content-center align-items-end">
                    <button className="btn btn-primary" style={{ height: '35px' }} onClick={handleSubmit}>Save Geofence</button>
                </div>
                <div className="d-flex col-1 p-3 justify-content-center align-items-end">
                    <button className="btn btn-secondary" style={{ height: '35px' }} onClick={handleUndo}>Undo</button>
                </div>
            </div>
            <div className="col-md-12" style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'YOUR_GOOGLE_MAPS_API_KEY' }}
                    defaultCenter={center}
                    defaultZoom={14}
                    onClick={({ lat, lng }) => {
                        setPolygonHistory([...polygonHistory, newPolygon]);
                        setNewPolygon([...newPolygon, { lat, lng }]);
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                        setMapInstance(map);
                        setMapsInstance(maps);
                    }}
                />
            </div>
        </div>
    );
};

export default AddGeofence;
