import { createEntityAdapter } from "@reduxjs/toolkit";

const iecAdaptor = createEntityAdapter({
    selectId: (entity) => entity.inc_id,
});

const helperAdaptor = createEntityAdapter({
    selectId: (entity) => entity.helper_id,
})

const adminAdaptor = createEntityAdapter({
    // selectId: (entity) => entity.community_admin_id,
})


const districtsAdaptor = createEntityAdapter({
    selectId: (entity) => entity.district_id,
})
export const statesAdaptor = createEntityAdapter({
    selectId: (entity) => entity.state_id,
})


const driverAdaptor = createEntityAdapter({
    // selectId: (entity) => entity.driver_id,
})


const supervisorAdaptor = createEntityAdapter({
    selectId: (entity) => entity.supervisor_id,
})

const vehicleAdaptor = createEntityAdapter({
    selectId: (entity) => entity.vehicle_id,
})


const wardsAdaptor = createEntityAdapter({
    selectId: (entity) => entity.ward_id,
})

const communitiesAdaptor = createEntityAdapter({
    selectId: (entity) => entity.community_id,
})
const categoryAdaptor = createEntityAdapter({
    selectId: (entity) => entity.wc_id,
})
const subCategoryAdaptor = createEntityAdapter({
    selectId: (entity) => entity.wsc_wc_id,
})
 
const dailyWasteAdaptor = createEntityAdapter({
    selectId: (entity) => entity.w_id,
})




export const adapter = {
    iec: iecAdaptor,
    helper: helperAdaptor,
    admin: adminAdaptor,
    districts: districtsAdaptor,
    states: statesAdaptor,
    driver: driverAdaptor,
    supervisor: supervisorAdaptor,
    vehicle: vehicleAdaptor,
    wards: wardsAdaptor,
    communities: communitiesAdaptor,
    category: categoryAdaptor,
    subCategory: subCategoryAdaptor,
    
    dailyWaste: dailyWasteAdaptor

};