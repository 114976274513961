
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useEffect, useState } from "react";
import { useGetIecTeamQuery } from "./iecTeamSlice";
import { useNavigate } from "react-router-dom";
import Pagination from "../../utils/Pagination";

const IECTeamList = () => {
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })
    useEffect(() => {
        localStorage.setItem('dailyReportPage', page);
    }, [page]);
    const navigate = useNavigate();

    const {
        data: iecTeam,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetIecTeamQuery({ page })

    let totalPages = 0;

    if (iecTeam && iecTeam.entities) {
        totalPages = iecTeam.totalPage;
    }

    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="row">
                    {/* <div className="col-md-6 text-left">
                        <h3 className="page-title">IEC Team</h3>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />

                        </ul>
                    </div> */}
                    <div className="col-md-6 text-right">

                    </div>
                </div>

                <div className="card card-dark mb-3" >
                    <div className="table-border-style" >
                        <div className="table-responsive"  >
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {iecTeam?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="listData text-left fw-normal" onClick={() => { navigate(`/b2g/iecteamdetail/${e.id}/${page}/${index}`); }}>
                                                    <td>{index + 1 * (page * 10 - 9)}</td>
                                                    <td>{e.name}</td>
                                                    <td>{e.number}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination page={page} totalPages={totalPages} setPage={setPage} />
            </div>
        </>
    )
}

export default IECTeamList;