import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import DarkMode from "../../../../pages/darkmode/DarkMode";
import Mess from "../../../../pages/Mess";
import AdminUpdateInformation from "./AdminUpdateInformation";
import AdminUpdatePassword from "./AdminUpdatePassword";
import { logout } from '../../../auth/authSlice'
import { useDispatch } from "react-redux";
const Sidebarprofile = ({ urlMain, urlGov, token, role }) => {

    const [employeeName, setEmployeeName] = useState("");
    const [roleName, setRoleName] = useState("");
    const [name, setName] = useState("");
    const [roleId, setRoleId] = useState("");
    const refOpenUpdate = useRef(null);
    const refOpenInformation = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logout())
    };

    const updatePassword = () => refOpenUpdate.current.click();
    const updateInformation = () => refOpenInformation.current.click();

    const passTheName = async () => {
        try {
            const response = await fetch(`${urlMain}/permissions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setEmployeeName(data.data[0]?.EmployeeName);
                setRoleName(data.data[0]?.RoleName);
            } else if (data.statusCode === 401) {
                dispatch(logout())
            }
        } catch (error) {
            console.error("Error in pass the name:", error);
        }
    };

    const passTheUsers = async () => {
        try {
            const response = await fetch(`${urlGov}/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setName(data.data[0]?.name);
                setRoleId(data.data[0]?.RoleId);
            }

        } catch (error) {
            console.error("Error in pass the users:", error);
        }
    };

    useEffect(() => {
        role === 6 ? passTheUsers() : passTheName();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Mess />
            <ul className="sidebarProfile">
                <li className="sidebarprofile_List" onClick={() => navigate("/main:profile")}>
                    <i className="fa-solid fa-user"></i>  &nbsp; Profile
                </li>
                <li className="sidebarprofile_List" onClick={updatePassword}>
                    <i className="fa-solid fa-lock text-light"></i> &nbsp; Update Password
                </li>
                <li className="sidebarprofile_List" onClick={handleLogout}>
                    <i className="fa-solid fa-right-from-bracket "></i>&nbsp; Logout
                </li>
            </ul>

            <AdminUpdateInformation urlGov={urlGov} token={token} refOpenInformation={refOpenInformation} />
            <AdminUpdatePassword urlMain={urlMain} refOpenUpdate={refOpenUpdate} />
        </>
    )
}

export default Sidebarprofile;