import React, { useState, useRef } from "react";
import { useAddNewWasteCategoryMutation } from "./categorySlice";

const AddWasteCategoryOffCanvas = () => {

    const [addNewCategory, { isLoading }] = useAddNewWasteCategoryMutation()
    const [addCategory, setAddCategory] = useState({ name: "" });
    const refClose = useRef(null);

    const handleChange = (e) => setAddCategory({ ...addCategory, [e.target.name]: e.target.value });

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewCategory({ 'wc_name': addCategory.name }).unwrap()
            setAddCategory({ name: "" })
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }
    return (
        <>
            <button type="button" className="Add_button bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Category</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Category</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Category Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={addCategory.name}
                                    onChange={handleChange}
                                    placeholder="Ex: Glass"
                                    autoComplete="off"
                                    required
                                />
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                    disabled={!(addCategory.name)}>
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddWasteCategoryOffCanvas;