import { createSlice } from "@reduxjs/toolkit";


const navSlice = createSlice({
    name: 'nav',
    initialState: { button: false, },

    reducers: {
        increment: (state) => {
            if (state.button === true) {
                state.button = false;
            } else {
                state.button = true;
            }
        },
    },
});

export const { increment } = navSlice.actions;
export default navSlice.reducer; 