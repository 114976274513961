import { useGetSupervisorQuery } from "./supervisorSlice";

const SupervisorExcerpt = ({ supervisorid, index }) => {
    const { supervisor } = useGetSupervisorQuery('getSupervisor', {
        selectFromResult: ({ data }) => ({
            supervisor: data?.entities[supervisorid]
        }),
    })

    return (
        <>
            <tr key={index} className="listData text-left fw-normal">
                <td>{index + 1}</td>
                <td>{supervisor?.name}</td>
                <td>{supervisor?.number}</td>
                <td>{supervisor?.ward_number}</td>
            </tr>
        </>
    )
}

export default SupervisorExcerpt